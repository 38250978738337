"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.F_CODE = exports.F_ERROR_PREFIX = void 0;
exports.F_ERROR_PREFIX = `FCPA`;
/* forward stack trace */
var F_CODE;
(function (F_CODE) {
    // ==============
    // GENERAL ERRORS
    // ==============
    // 4xx
    F_CODE["FCPA_400"] = "Bad Request";
    F_CODE["FCPA_401"] = "Unauthorized";
    F_CODE["FCPA_402"] = "Payment Required";
    F_CODE["FCPA_403"] = "Forbidden";
    F_CODE["FCPA_404"] = "Not Found";
    F_CODE["FCPA_405"] = "Method Not Allowed";
    F_CODE["FCPA_406"] = "Not Acceptable";
    F_CODE["FCPA_407"] = "Proxy Authentication Required";
    F_CODE["FCPA_408"] = "Request Timeout";
    F_CODE["FCPA_409"] = "Conflict";
    F_CODE["FCPA_410"] = "Gone";
    F_CODE["FCPA_411"] = "Length Required";
    F_CODE["FCPA_412"] = "Precondition Failed";
    F_CODE["FCPA_413"] = "Payload Too Large";
    F_CODE["FCPA_414"] = "URI Too Long";
    F_CODE["FCPA_415"] = "Unsupported Media Type";
    F_CODE["FCPA_416"] = "Range Not Satisfiable";
    F_CODE["FCPA_417"] = "Expectation Failed";
    F_CODE["FCPA_418"] = "I`m a teapot (RFC 2324, RFC 7168)";
    F_CODE["FCPA_421"] = "Misdirected Request";
    F_CODE["FCPA_422"] = "Unprocessable Entity";
    F_CODE["FCPA_423"] = "Locked (WebDAV; RFC 4918)";
    F_CODE["FCPA_424"] = "Failed Dependency (WebDAV; RFC 4918)";
    F_CODE["FCPA_425"] = "Too Early (RFC 8470)";
    F_CODE["FCPA_426"] = "Upgrade Required";
    F_CODE["FCPA_428"] = "Precondition Required (RFC 6585)";
    F_CODE["FCPA_429"] = "Too Many Requests (RFC 6585)";
    F_CODE["FCPA_431"] = "Request Header Fields Too Large (RFC 6585)";
    F_CODE["FCPA_451"] = "Unavailable For Legal Reasons (RFC 7725)";
    // 5xx
    F_CODE["FCPA_500"] = "Internal Server Error";
    F_CODE["FCPA_501"] = "Not Implemented";
    F_CODE["FCPA_502"] = "Bad Gateway";
    F_CODE["FCPA_503"] = "Service Unavailable";
    F_CODE["FCPA_504"] = "Gateway Timeout";
    F_CODE["FCPA_505"] = "HTTP Version not supported";
    F_CODE["FCPA_506"] = "Variant Also Negotiates";
    F_CODE["FCPA_507"] = "Insufficient Storage";
    F_CODE["FCPA_508"] = "Loop Detected";
    F_CODE["FCPA_509"] = "Bandwidth Limit Exceeded";
    F_CODE["FCPA_510"] = "Not Extended";
    F_CODE["FCPA_511"] = "Network Authentication Required";
    // =============
    // CUSTOM ERRORS
    // =============
    /*
     * Custom errors follow this convention: FDI_<HTTP_STATUS_CODE>_<AREA>_<SPECIFIC_ERROR_CODE>
     *
     * The <AREA> helps organizing the error codes into topics where the error originated
     *
     * AREAS:
     * 01: General Service
     * 02: Firebase
     * 03: Geolocation Service
     * 10-99: Farmact resources
     *
     * The <SPECIFIC_ERROR_CODE> is a numeric code with 4 digits ranging from 0001 - 9999
     * giving detail about the specific error in the given <AREA>
     */
    // 01: General Service
    /* 400 */
    F_CODE["FCPA_400_01"] = "Bad Request (General Service)";
    F_CODE["FCPA_400_01_0001"] = "Cannot determine the companyId from the request";
    F_CODE["FCPA_400_01_0002"] = "Cannot determine the userId from the token";
    /* 401 */
    F_CODE["FCPA_401_01"] = "Unauthorized (General Service)";
    F_CODE["FCPA_401_01_0001"] = "Cannot verify the token";
    F_CODE["FCPA_401_01_0002"] = "Cannot extract token from the request";
    /* 403 */
    F_CODE["FCPA_403_01"] = "Forbidden (General Service)";
    F_CODE["FCPA_403_01_0001"] = "No access to requested company";
    F_CODE["FCPA_403_01_0002"] = "Action requires 'canEditStructures'";
    F_CODE["FCPA_403_01_0003"] = "Customer has no access to any operatingUnitId";
    F_CODE["FCPA_403_01_0004"] = "Customer has no access to orderRequests";
    // 02: Firebase
    /* 404 */
    F_CODE["FCPA_404_02"] = "Not Found (Firebase)";
    F_CODE["FCPA_404_02_0001"] = "Cannot find resource in firebase";
    /* 500 */
    F_CODE["FCPA_500_02"] = "Internal Server Error (Firebase)";
    F_CODE["FCPA_500_02_0001"] = "Something went wrong while getting resource(s) from firebase";
    F_CODE["FCPA_500_02_0002"] = "Something went wrong while creating resource(s) in firebase";
    F_CODE["FCPA_500_02_0003"] = "Something went wrong while updating resource(s) in firebase";
    F_CODE["FCPA_500_02_0004"] = "Something went wrong while deleting resource(s) in firebase";
    F_CODE["FCPA_500_02_0100"] = "Cannot determine collection path";
    // 03: Geolocation Service
    /* 404 */
    F_CODE["FCPA_404_03"] = "Not Found (Geolocation Service)";
    F_CODE["FCPA_404_03_0001"] = "No geolocation found for provided address";
    /* 500 */
    F_CODE["FCPA_500_03"] = "Internal Server Error (Geolocation Service)";
    F_CODE["FCPA_500_03_0001"] = "Something went wrong while getting geolocation";
    //  10: Farmact resources (Structures)
    /* 403 */
    F_CODE["FCPA_403_10"] = "Forbidden (Structures)";
    F_CODE["FCPA_403_10_0001"] = "Resource(s) not accessible by current customer";
    // 20: Farmact resources (OrderRequests)
    F_CODE["FCPA_403_20"] = "Forbidden (OrderRequests)";
    F_CODE["FCPA_403_20_0001"] = "OrderRequest not accessible by current customer";
    F_CODE["FCPA_403_20_0002"] = "Customer has no access to change orderRequests that are not in draft oder pending state";
})(F_CODE || (exports.F_CODE = F_CODE = {}));
